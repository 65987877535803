const api = process.env.REACT_APP_PRECO_SUPREMO_API

export const getProductsHotsLoader = () => {
    return fetch(`${api}/api/v1/products/hots`)
}

export const getProductsByCategoryLoader = ({ params }) => {
    return fetch(`${api}/api/v1/products/category/${params.category}`)
}

export const getCpuSpecificationLoader = ({ request }) => {
    return getSpecificationLoader(request, "CPU")
}

export const getGpuSpecificationLoader = ({ request }) => {
    return getSpecificationLoader(request, "GPU")
}

const getSpecificationLoader = (request, category) => {

    const url = new URL(request.url)
    const productModelId = url.searchParams.get("productModelId")
    const specificationId = url.searchParams.get("specificationId")

    return fetch(`${api}/api/v1/products/category/${category}/specs?productModelId=${productModelId}&specificationId=${specificationId}`)
}
