import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"

import { useTheme } from "@mui/material/styles"
import { 
    forwardRef, 
    useEffect, 
    useImperativeHandle, 
    useMemo, 
    useState, 
    Fragment,
} from "react"

const ProductTable = forwardRef((props, ref) => {

    const theme = useTheme()
    const rowsPerPage = 10
    const { data, setSelectedProduct } = props

    const [isLoading, setIsLoading] = useState(true)
    const [productDetails, setProductDetails] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [tableRowColorArray, setTableRowColorArray] = useState(new Array(10))
    const [page, setPage] = useState(0)

    const isDarkMode = () => {
        return localStorage.theme === "dark"
    }

    useEffect(() => {

        setIsLoading(true)

        const initTableRowColor = () => {
            const trc = new Array(10)
            
            for (let i = 0; i < trc.length; i++) {
                if (i % 2 === 0) {
                    trc[i] = isDarkMode() ? "#404040" : "#F0F0F0" 
                } else {
                    trc[i] = isDarkMode() ? "" : ""
                }
            }
        
            trc[0] = theme.palette.primary.dark
        
            setTableRowColorArray(trc)
            setSelectedIndex(0)
        }

        setProductDetails(data.products)
        initTableRowColor()

        setIsLoading(false)
    }, [data, theme.palette.primary.dark])


    const toUpperFirst = (str) => {
        const result = str.toLowerCase()
        const firstLetter = str[0]

        return firstLetter + result.slice(1, result.length)
    }

    const formatPrice = (price) => {
        return "R$ " + Number(price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })
    }

    const visibleRows = useMemo(
        () => productDetails.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [page, rowsPerPage, productDetails],
    )

    const storeBtnColorMap = new Map([
        ["KABUM", "#FE6400"],
        ["PICHAU", "#A3020D"],
        ["TERABYTE", "#222222"],
        ["GKINFO", "#4AB931"],
        ["PATOLOCO", "#001646"],
        ["MAGALU", "#0086FF"],
        ["AMAZON", "#7A4E23"],
    ])

    const selectItem = (index, arrayIndex) => {
        setSelectedProduct(productDetails[arrayIndex])
        onClickTable(index)
    }

    const onClickPrevious = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex => {
                const index = selectedIndex - 1
                selectItem(index, (page * rowsPerPage) + index)

                return index
            })
        }
    }

    const onClickNext = () => {
        if (selectedIndex < (visibleRows.length - 1)) {
            setSelectedIndex(selectedIndex => {
                const index = selectedIndex + 1
                selectItem(index, (page * rowsPerPage) + index)

                return index
            })
        }
    }

    const onClickTable = (index) => {
        const trc = tableRowColorArray

        for (let i = 0; i < trc.length; i++) {
            if (i % 2 === 0) {
                trc[i] = isDarkMode() ? "#404040" : "#F0F0F0" 
            } else {
                trc[i] = isDarkMode() ? "" : ""
            }
        }

        trc[index] = theme.palette.primary.dark

        setTableRowColorArray(trc)
        setSelectedIndex(index)
    }

    const onOverRowColor = () => {
        if (isDarkMode()) {
            return theme.palette.secondary.dark
        }

        return theme.palette.secondary.light
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
        setSelectedProduct(productDetails[newPage * rowsPerPage])
        onClickTable(0)
    }

    useImperativeHandle(ref, () => {
        return {
            onClickPrevious,
            onClickNext,
        }
    })

    function Row(props) {
        const { row, index, arrayIndex } = props

        return (
          <Fragment>
            <TableRow sx={{ "& > *": { borderBottom: "unset" }, backgroundColor: tableRowColorArray[index], ":hover": { backgroundColor: onOverRowColor() } }} onClick={() => selectItem(index, arrayIndex)}>
              <TableCell className="max-sm:hidden w-1/12" align="center"><span>{arrayIndex + 1}</span></TableCell>
              <TableCell className="w-6/12" component="th" scope="row">{row.brandModelOriginal}</TableCell>
              <TableCell className="max-sm:hidden w-1/12">{row.brand}</TableCell>
              <TableCell className="w-2/12" align="right">{formatPrice(row.price)}</TableCell>
              <TableCell className="w-2/12" align="center">
              <div className="flex self-center flex-col items-center">
                <Button variant="contained" sx={{ width: 80, minWidth: 80, maxWidth: 80, backgroundColor: storeBtnColorMap.get(row.site) }} href={row.storeLink} target="_blank">
                  <span className="capitalize text-white">{toUpperFirst(row.site)}</span>
                </Button>
                {/* { row.affiliate && <span className="text-xs text-center italic text-green-600">patrocinado</span> } */}
                </div>
              </TableCell>
            </TableRow>
          </Fragment>
        )
  }

    if (!isLoading) {
        return (
          <div className="w-full">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                  <TableCell className="max-sm:hidden"/>
                  <TableCell>Modelo</TableCell>
                  <TableCell className="max-sm:hidden">Marca</TableCell>
                  <TableCell align="right">Preço</TableCell>
                  <TableCell align="center">Loja</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {visibleRows.map((spec, index) => (
                  <Row key={index} index={index} arrayIndex={(page * rowsPerPage) + index} row={spec}/>
                ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={productDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </div>
        )
    }
})

export default ProductTable