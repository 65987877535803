import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

const Spinner = () => {

    return (
        <div className="flex justify-center items-center h-screen">
          <div className="flex flex-col justify-center items-center">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress size="5rem" />
            </Box>
          </div>
        </div>
    )
}

export default Spinner