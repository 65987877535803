const Footer = () => {
    return (
      <div className="bg-gray-200 py-20 px-10 text-gray-900 text-center">
        <p><a href="mailto:contato@precosupremo.com.br">contato@precosupremo.com.br</a></p>
        <br/>
        <p>Copyright © 2024 Preço Supremo</p>
      </div>
    )
  }
  
  export default Footer