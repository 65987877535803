import AddCircleIcon from "@mui/icons-material/AddCircle"
import Card from "@mui/material/Card"
import IconButton from "@mui/material/IconButton"

import Image from "../components/Image"
import ProductTable from "../components/ProductTable"
import Spinner from "../components/Spinner"

import { useRef, useState } from "react"
import { useLoaderData, useNavigation } from "react-router-dom"

const CpuSpecificationPage = () => {

    const json = useLoaderData()
    const navigation = useNavigation()
    const productTableRef = useRef()

    const data = json.data

    const [selectedProduct, setSelectedProduct] = useState(data.products[0])
    const [specification] = useState(data.specification)

    const showData = (data) => {
        return (data > 0 || (data && data.length && data.length > 0)) ? data : "-"
    }

    const showFrequency = (frequency) => {
        return frequency > 0 ? frequency + " GHz" : "-" 
    }

    const showTdp = (tdp) => {
        return tdp > 0 ? tdp + " W" : "-"
    }
  
    if (navigation.state === "loading") {
      return <Spinner />
    }

    return (
      <div className="container flex items-center flex-col mx-auto sm:px-10">
        <div className="w-full">
          <div className="flex flex-col lg:flex-row">
            <Image product={selectedProduct} productTableRef={productTableRef} />
            <div className="xl:w-4/12 md:pt-10 pb-5">
              <Card className="flex flex-col items-center py-5 pl-5 pr-0 h-[480px]" variant="outlined">
                <div className="flex self-stretch pr-5 overflow-y-auto">
                  <form className="w-full">
                    <div className="flex flex-col -mx-3">
                      <div className="flex justify-end">
                        <div className="w-full md:w-1/1 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-manufacturer">
                            Fabricante
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-manufacturer" type="text" disabled value={selectedProduct.manufacturer}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-model">
                            Modelo
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-model" type="text" disabled value={selectedProduct.model}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-6/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-socket">
                            Socket
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-socket" type="text" disabled value={specification.socket}/>
                        </div>
                        <div className="w-6/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-technology">
                            Tecnologia
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-technology" type="text" disabled value={specification.technology}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                        <div className="w-full w-1/3 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-cores-p">
                            Núcleos Performance
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-cores-p" type="text" disabled value={specification.coresP}/>
                        </div>
                        <div className="w-full w-1/3 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-cores-e">
                            Núcleos Eficiência
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-cores-e" type="text" disabled value={specification.coresE}/>
                        </div>
                        <div className="w-full w-1/3 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-threads">
                            Threads
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-threads" type="text" disabled value={specification.threads}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                        <div className="w-full w-1/3 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-frequency-base">
                            Frequência Base
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-frequency-base" type="text" disabled value={showFrequency(specification.frequencyBase)}/>
                        </div>
                        <div className="w-full w-1/3 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-frequency-max">
                            Frequência Máxima
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-frequency-max" type="text" disabled value={showFrequency(specification.frequencyMax)}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                      <div className="w-1/2 px-3 ">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-tdp-base">
                            TDP Base
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-tdp-base" type="text" disabled value={showTdp(specification.tdpBase)}/>
                        </div>
                        <div className="w-1/2 px-3 ">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-tdp-max">
                            TDP Máximo
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-tdp-max" type="text" disabled value={showTdp(specification.tdpMax)}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                        <div className="w-full w-1/2 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-cache-l3">
                            Cache L3
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-cache-l3" type="text" disabled value={specification.cacheL3 + " MB"}/>
                        </div>
                        <div className="w-full w-1/2 px-3 ">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-unlocked">
                            Desbloqueado
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-unlocked" type="text" disabled value={specification.unlocked ? "SIM" : "NÃO"}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                        <div className="w-full px-3 ">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-memory">
                            Memória
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-memory" type="text" disabled value={showData(specification.memoryType)}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3 items-end">
                        <div className="w-full px-3 ">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-memory-speed">
                            Velocidade de Memória
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-memory-speed" type="text" disabled value={showData(specification.memorySpeed)}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-full md:w-1/1 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-pcie">
                            PCIe
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-pcie" type="text" disabled value={specification.pcieVersion}/>
                        </div>
                        <div className="pr-3 self-center">
                          <IconButton href={selectedProduct.brandLink} rel="external" target="_blank"><AddCircleIcon/></IconButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </div>
          </div>
          <ProductTable data={data} setSelectedProduct={setSelectedProduct} ref={productTableRef}/>
        </div>
      </div>
    )
}

export default CpuSpecificationPage