import Box from "@mui/system/Box"
import IconButton from "@mui/material/IconButton"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import SkipNextIcon from "@mui/icons-material/SkipNext"
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious"

import { useTheme } from "@mui/material/styles"

const Image = ({ product, productTableRef }) => {

    const theme = useTheme()

    const onClickPrevious = () => {
        productTableRef.current?.onClickPrevious()
    }

    const onClickNext = () => {
        productTableRef.current?.onClickNext()
    }

    return (
      <div className="flex justify-center max-lg:w-full w-8/12 xl:w-8/12 max-h-full flex items-center py-5">
        <div className="flex flex-col items-stretch h-full self-start lg:min-w-[40rem] w-full">
          <div className="flex min-h-[80px] py-0 max-sm:mt-10 mb-0 self-center">
            <Box className="flex self-stretch items-center">
              <CardContent className="m-0 py-0 px-5">
                <Typography className="text-center" component="div" variant="h5">{product.brandModelOriginal}</Typography>
              </CardContent>
            </Box>
          </div>
          <div className="flex w-full justify-between">
            <Box className="flex items-center px-4" onClick={() => onClickPrevious()}>
              <IconButton aria-label="previous">
                {theme.direction === "rtl" ? <SkipNextIcon /> : <SkipPreviousIcon />}
              </IconButton>
              </Box>
            <div className="flex h-[300px] md:h-[400px] py-5 m-auto items-center">
              <CardMedia className="object-scale-down max-h-80 max-w-80" component="img" image={product.image} alt="Imagem da placa de vídeo"/>
            </div>
            <Box className="flex items-center px-4" onClick={() => onClickNext()}>
              <IconButton aria-label="next">
                {theme.direction === "rtl" ? <SkipPreviousIcon /> : <SkipNextIcon />}
              </IconButton>
            </Box>
          </div>
        </div>
      </div>
    )
}

export default Image