import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { 
  AppBar, 
  Box, 
  Button, 
  Container, 
  IconButton, 
  MenuItem, 
  MenuList, 
  Toolbar, 
  Typography 
} from "@mui/material"

import { Menu as MenuIcon } from "@mui/icons-material"

const NavBar = () => {

    const navigate = useNavigate()

    const [selectedCategory, setSelectedCategory] = useState('')
    const [open, setOpen] = useState(false)

    const para = useRef(null)

    const Categories = {
        CPU: "CPU",
        GPU: "GPU",
      }
    
    const categories = [
      "Processadores",
      "Placas de Vídeo",
    ]
  
    const handleMenuToggle = (_) => {
      setOpen(!open)
    }

    const onClickCategorie = (_, index) => {
        var selectedCategory = Object.keys(Categories)[index]
        setSelectedCategory(selectedCategory)
        navigate(`/products/category/${selectedCategory}`)
    }

    return (
      <AppBar className="mb-5" position="static">
        <Container className="container flex lg:items-center lg:flex-col lg:mx-auto">
          <Toolbar disableGutters>
            <Typography className="capitalize"
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              Home
            </Typography>

            <IconButton onClick={handleMenuToggle}
              id="menu-button"
              aria-controls={open ? 'menu-list' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4">           
              <Box sx={{ display: open ? '' : 'none' }} >
                <div className=" w-full md:block md:w-auto" id="navbar-default">
                  <MenuList
                    id="menu-list"
                    aria-labelledby="menu-list"
                    open={open}
                  >
                  {categories.map((category, i) => (
                    <MenuItem 
                      ref={para}
                      className={"select-none cursor-pointer font-semibold " + (Object.keys(Categories)[i] === selectedCategory ? "underline" : "") }
                      key={i} 
                      onClick={e => onClickCategorie(e, i)}>
                      <Typography className="normal-case" textAlign="center">{category}</Typography>
                    </MenuItem>
                  ))}
                  </MenuList>      
                </div>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>            
              {categories.map((category, i) => (
                <Button
                  ref={para}
                  className={"select-none cursor-pointer font-semibold " + (Object.keys(Categories)[i] === selectedCategory ? "underline" : "") }
                  key={i}
                  onClick={e => onClickCategorie(e, i)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  <span className="normal-case">{category}</span>
                </Button>
              ))}
              </Box>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    )
}

export default NavBar
