import NavBar from "../components/NavBar"
import NotFound from "../components/NotFound"
import SingleProduct from "../components/SingleProduct"
import Spinner from "../components/Spinner"

import { useLoaderData, useNavigation } from "react-router-dom"

const Products = () => {

    const json = useLoaderData()
    const navigation = useNavigation()

    const FROM_LAST_YEARS = 3

    const products = json.data.products
    const filteredProducts = products.filter(p => {
            if (p.launchAt === "") {
                return true
            }
            
            return new Date(p.launchAt).getYear() >= (new Date().getYear() - FROM_LAST_YEARS)
        })
        .sort((a, b) => {
            if (a.launchAt === "" && b.launchAt === "") {
                return 0
            }

            if (a.launchAt === "" && b.launchAt !== "") {
                return 1
            }

            if (a.launchAt !== "" && b.launchAt === "") {
                return -1
            }

            return (new Date(b.launchAt).getYear() - new Date(a.launchAt).getYear() || new Date(b.launchAt).getMonth() - new Date(a.launchAt).getMonth())
        })

    if (navigation.state === "loading") {
      return <Spinner />
    }

    return (
      <div>
        <NavBar />
        <div className="container flex items-center flex-col mx-auto pb-20 px-10">
          <div className="flex justify-center w-full gap-10">
            <div>
              <div className="grid justify-items-center gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                {filteredProducts &&
                  filteredProducts.map((product, index) => (
                    <SingleProduct key={index} product={product} />
                  ))}
              </div>
            </div>
          </div>
          {(() => {
            if (filteredProducts === undefined || filteredProducts === null || filteredProducts.length === 0) {
              return <NotFound/>
            }
          })()}
        </div>
      </div>
    )
}

export default Products