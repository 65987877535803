import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

import { useNavigate } from "react-router-dom"
import { faCircleDown } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SingleProduct = ({ product }) => {
    const { title, productModelId, image, specificationId, category } = product;

    const price = Number(product.price)
    const minimumPrice = Number(product.minimumPrice)

    const navigate = useNavigate();

    const onClickSeeMore = () => navigate(`/products/category/${category}/specs?productModelId=${productModelId}&specificationId=${specificationId}`);

    const minPrice = () => {
        if (price <= minimumPrice)  {
          return (
            <Tooltip title="Menor preço histórico!">
              <IconButton aria-label="low-price" sx={{backgroundColor: "rgba(255, 255, 255, 1)", padding: "2px", border: "1px solid gray"}}>
                <FontAwesomeIcon icon={faCircleDown} beat size="sm" style={{"color": "#008000",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else if (price <= (minimumPrice * 1.05)) {
          return (
            <Tooltip title="Ótimo preço!">
              <IconButton aria-label="low-price" sx={{backgroundColor: "rgba(255, 255, 255, 1)", padding: "2px", border: "1px solid gray"}}>
                <FontAwesomeIcon icon={faCircleDown} beat size="sm" style={{"color": "#888800",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else if (price <= (minimumPrice * 1.10)) {
          return (
            <Tooltip title="Bom preço!">
              <IconButton aria-label="low-price" sx={{backgroundColor: "rgba(255, 255, 255, 1)", padding: "2px", border: "1px solid gray"}}>
                <FontAwesomeIcon icon={faCircleDown} beat size="sm" style={{"color": "#008888",}} />
              </IconButton>
            </Tooltip>
          )
        }
        else {
          return ""
        }
    }

    return (
      <div className="flex items-center max-w-[275px] flex-col bg-gray-50 gap-3 shadow-md hover:shadow-xl hover:scale-105 duration-300 px-4 py-7 rounded-sm overflow-hidden dark:bg-[#404040]">
        <h2 className="flex justify-center text-stone-950 font-semibold text-sm h-10 dark:text-white">
          {title}
        </h2>
        <div className="flex justify-center relative">
          <img
            className="w-72 h-48 object-contain"
            src={image}
            alt={title}
          />
          <div className="rounded-lg absolute self-start right-0 flex px-3 label-border">
            { minPrice() }
          </div> 
        </div>
        <div className="flex flex-col self-stretch gap-0.5 px-5 md:px-1 2xl:px-5">
          <div className="flex justify-between text-sm text-gray-600 gap-5 dark:text-white">
            <span>Preço</span><span className="text-green-600 font-semibold text-xl">R$ {price.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</span>
          </div>
          <div className="flex w-30 justify-center pt-5">
            <Button variant="contained" color="success" onClick={onClickSeeMore}>Ver Mais</Button>
          </div>
        </div>
      </div>
    )
}

export default SingleProduct