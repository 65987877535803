import AddCircleIcon from "@mui/icons-material/AddCircle"
import Card from "@mui/material/Card"
import IconButton from "@mui/material/IconButton"

import Image from "../components/Image"
import ProductTable from "../components/ProductTable"
import Spinner from "../components/Spinner"

import { useRef, useState } from "react"
import { useLoaderData, useNavigation } from "react-router-dom"

const GpuSpecificationPage = () => {

    const json = useLoaderData()
    const navigation = useNavigation()
    const productTableRef = useRef()

    const data = json.data

    const [selectedProduct, setSelectedProduct] = useState(data.products[0])
    const [specification] = useState(data.specification)

    const showData = (data) => {
        return (data > 0 || (data && data.length && data.length > 0)) ? data : "-"
    }

    if (navigation.state === "loading") {
      return <Spinner />
    }

    return (
      <div className="container flex items-center flex-col mx-auto sm:px-10">
        <div className="w-full">
          <div className="flex flex-col lg:flex-row">
            <Image product={selectedProduct} productTableRef={productTableRef} />
            <div className="xl:w-4/12 md:pt-10 pb-5">
              <Card className="flex flex-col items-center py-5 pl-5 pr-0 h-[480px]" variant="outlined">
                <div className="flex self-stretch pr-5 overflow-y-auto">
                  <form className="w-full">
                    <div className="flex flex-col -mx-3">
                      <div className="flex justify-end">
                        <div className="w-full md:w-1/1 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-manufacturer">
                            Fabricante
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-manufacturer" type="text" disabled value={selectedProduct.manufacturer}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-model">
                            Modelo
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-model" type="text" disabled value={selectedProduct.model}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-7/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-bus">
                            PCI
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-bus" type="text" disabled value={specification.bus}/>
                        </div>
                        <div className="w-5/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-shaders">
                            Shaders
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-shaders" type="text" disabled value={specification.shaders}/>
                        </div>
                      </div>
                      <div className="flex justify-end mb-3">
                        <div className="w-7/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-memory">
                            Memória
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-memory" type="text" disabled value={specification.memoryCapacity + " GB " + specification.memoryType}/>
                        </div>
                        <div className="w-5/12 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-memory-interface">
                            Interface
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="input-memory-interface" type="text" disabled value={specification.memoryInterface + " bits"}/>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <div className="w-full md:w-1/1 px-3">
                          <label className="block uppercase tracking-wide text-gray-700 dark:text-white text-xs font-bold mb-2" htmlFor="input-clock-boost">
                            Boost Clock
                          </label>
                          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="input-clock-boost" type="text" disabled value={showData(selectedProduct.customSpecification.clockBoost)}/>
                        </div>
                        <div className="pr-3 self-center">
                          <IconButton href={selectedProduct.brandLink} rel="external" target="_blank"><AddCircleIcon/></IconButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Card>
            </div>
          </div>
          <ProductTable data={data} setSelectedProduct={setSelectedProduct} ref={productTableRef}/>
        </div>
      </div>
    )
}

export default GpuSpecificationPage