import NavBar from "../components/NavBar"
import SingleProduct from "../components/SingleProduct"
import Spinner from "../components/Spinner"

import { useLoaderData, useNavigation } from "react-router-dom"

const Home = () => {

    const json = useLoaderData()
    const navigation = useNavigation()

    const products = json.data.products

    if (navigation.state === "loading") {
        return <Spinner />
    }

    return (
      <div>
        <NavBar />
        <div className="container flex items-center flex-col mx-auto pb-20 px-10">
          <div className="flex justify-center gap-10">
            <div>
              <div className="grid justify-items-center gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                {products &&
                  products.slice(0, 20).map((product, index) => (
                    <SingleProduct key={product.number + "" + index} product={product} />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Home