import CssBaseline from "@mui/material/CssBaseline"

import Product from "./pages/Product"
import GpuSpecificationPage from "./pages/GpuSpecificationPage"
import CpuSpecificationPage from "./pages/CpuSpecificationPage"
import Home from "./pages/Home"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Spinner from "./components/Spinner"

import { ThemeProvider, createTheme } from "@mui/material/styles"
import { ptBR } from "@mui/material/locale"
import { useState, useCallback } from "react"

import {
    createBrowserRouter,
    RouterProvider,
    Outlet, 
    ScrollRestoration
} from "react-router-dom"

import { 
    getProductsHotsLoader,
    getProductsByCategoryLoader,
    getCpuSpecificationLoader,
    getGpuSpecificationLoader,
} from "./loaders/api-loaders"

const defaultTheme = createTheme({}, ptBR)

const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    }, 
    ptBR
)


const LayoutComponent = () => {

    const [darkmode, setDarkmode] = useState(localStorage.theme === 'dark' ? true : false)

    let getKey = useCallback(
        (location, matches) => {
            let match = matches.find((m) => (m.handle)?.scrollMode)
            if ((match?.handle)?.scrollMode === "pathname") {
                return location.pathname
            }

            return location.key
        },
        []
    )

    const onDarkModeChange = (event) => {
        setDarkmode(event.target.checked)
        event.target.checked ? localStorage.theme = 'dark' : localStorage.theme = 'light'
    }

    return (
        <>
         <ThemeProvider theme={darkmode ? darkTheme : defaultTheme}>
            <CssBaseline />
            <section className="">
              <Header onDarkModeChange={onDarkModeChange} darkmode={darkmode} />
              <main className="">
                <Outlet /> 
              </main>

              <Footer />
            </section>
          </ThemeProvider>
          <ScrollRestoration getKey={getKey} />
        </>
    )
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <LayoutComponent />,
        children: [
            {
                index: true,
                element: <Home />,
                loader: getProductsHotsLoader,
            },
            {
                path: "products/category/:category",
                element: <Product />,
                loader: getProductsByCategoryLoader,
            },
            {
                path: "products/category/CPU/specs",
                element: <CpuSpecificationPage />,
                loader: getCpuSpecificationLoader,
            },
            {
                path: "products/category/GPU/specs",
                element: <GpuSpecificationPage />,
                loader: getGpuSpecificationLoader,
            },
        ],
    },
])

const App = () => {
    return (
        <RouterProvider router={router} fallbackElement={<Spinner />} />
    )
}

export default App